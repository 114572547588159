import type { TokenResponse } from '@react-oauth/google';

import { useMainContext } from 'routes/MainContextProvider';
import { IntUserResponse } from 'services/local/types';
import catchErrorAndCloseLoading from 'functions/general/catchErrorAndCloseLoading';
import ModalSignIn from './ModalSignIn';
import { login as loginSuccess } from 'store/reducers/auth';
import { close as setCloseSignIn } from 'store/reducers/signIn';
import { SignInData } from './types';
import { GmailSignInData } from 'services/http/types';

const ModalSignInDispatcher = ({email = ''}: {email?: string}) => {
  const {
    api,
    local,
    toastr,
    history,
    dispatch,
    scrollToTop,
    openLoading,
    closeLoading,
    signIn,
    user,
    withOpenedSignIn
  } = useMainContext();

  const onSignInGmail = (googleUser: TokenResponse) => {
    const id_token = googleUser.access_token;
    const reportId = local.analysis.getLastFullReportId();
    const data: GmailSignInData = {
      token: id_token
    };
    if (reportId) {
      data.report_id = reportId
    }
    return api.auth.gmailSignIn(data);
  };
  const onClose = () => {
    dispatch(setCloseSignIn());
  };

  const onSend = (data: SignInData) => {
    openLoading('Login...');
    const reportId = local.analysis.getLastFullReportId();
    if (reportId) {
      data.reportId = reportId;
    }
    return api.auth.signIn(data).then((response: IntUserResponse) => {
      local.auth.setUser(response);
      dispatch(loginSuccess(response));
      closeLoading();
      scrollToTop();
      if (local.auth.isAdmin()) {
        history('/admin/users-administration');
      } else if (withOpenedSignIn) {
        history(0);//reload
      } else {
        history('/');
      }
      if (reportId) {
        window.location.reload();
      }
      return Promise.resolve(response);
    }).catch(catchErrorAndCloseLoading(closeLoading));
  };
  const onSendGmail = (googleUser: TokenResponse) => {
    openLoading('Login using gmail...');
    return onSignInGmail(googleUser).then((response: IntUserResponse) => {
      local.auth.setUser(response);
      dispatch(loginSuccess(response))
      closeLoading();
      scrollToTop();
      if (local.auth.isAdmin()) {
        history('/admin/users-administration');
      } else if (withOpenedSignIn) {
        history(0);//reload
      } else {
        history('/');
      }
      return Promise.resolve(response);
    }).catch(catchErrorAndCloseLoading(closeLoading));
  };
  const onForgot = (emailValue: string) => {
    openLoading('Loading...');
    return api.auth.passwordRecovery(emailValue).then((response: IntUserResponse) => {
      closeLoading();
      scrollToTop();
      toastr.success('', 'Done!, please check your email')
      return Promise.resolve(response);
    }).catch(catchErrorAndCloseLoading(closeLoading));
  };
  if (!((!user && withOpenedSignIn) || signIn) && !email) return null;
  return (
    <ModalSignIn
      defaultEmail={email}
      onClose={onClose}
      onSend={onSend}
      onSendGmail={onSendGmail}
      onForgot={onForgot} />
  );
};

export default ModalSignInDispatcher;
