import React from 'react';

import getIntegerFormatted from 'functions/general/getIntegerFormatted';
import useOnLogout from 'hooks/useOnLogout';
import hamburger from 'images/hamburger.svg';
import creditIcon from 'images/header/credit.svg';
import downIcon from 'images/header/down.svg';
import userIcon from 'images/header/user.svg';
import accountIcon from 'images/header/account.svg';
import documentsIcon from 'images/header/documents.svg';
import ga4Icon from 'images/header/ga4.svg';
import logoutIcon from 'images/header/logout.svg';
import { useMainContext } from 'routes/MainContextProvider';
import { open as setOpenSignIn } from 'store/reducers/signIn';
import { open as setOpenContactUs } from 'store/reducers/contactUs';
import usePendingCredits from './hooks/usePendingCredits';
import { Menu, Div, MenuDiv } from './elements';
import type { MenuDesktopProps, MenuItemValue } from './types';

const MobMenuItem = (props: MenuItemValue) => (
  <button type="button" tabIndex={0} role="menuitem" onClick={props.onClick}>
    <img src={props.icon} alt={props.name} />
    <span>{props.name}</span>
  </button>
);

const MenuDesktop = ({
  user,
  name,
  isOpen,
  setIsOpen,
  showAvailable
}: MenuDesktopProps) => {
  const { local, dispatch, history, isLoggedIn } = useMainContext();
  const isLoading = usePendingCredits();
  const isPrepaid = local.auth.isPrepaid();
  const hasOnlyLicence = local.auth.hasOnlyLicence();
  const hasMyDocuments = local.auth.hasMyDocuments();

  const logout = useOnLogout();

  const goMyDocuments = () => {
    if (local.auth.hasMyDocuments()) {
      history('/documents/my-documents/1');
    }
  };
  const goAccount = () => {
    local.selectedDocuments.remove();
    local.compareDocument.delCompareDocument();
    history('/account');
  };
  const goPayments = () => {
    if (!local.auth.isPrepaid() && !local.auth.hasOnlyLicence()) {
      local.selectedDocuments.remove();
      local.compareDocument.delCompareDocument();
    }
  };

  const onOpenSignIn = () => {
    dispatch(setOpenSignIn());
  };
  const onOpenUpgrade = (e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation();
    goPayments();
  };
  const contactUs = (e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation();
    dispatch(setOpenContactUs(''));
  };

  const signIn = (e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation();
    onOpenSignIn();
  };

  const toggle = (e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation();
    setIsOpen(!isOpen);
  };

  const singOut = (e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation();
    logout();
    local.selectedDocuments.remove();
    local.compareDocument.delCompareDocument();
    local.auth.delUser();
    setIsOpen(false);
  };

  const goToMyAccount = (e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation();
    local.selectedDocuments.remove();
    local.compareDocument.delCompareDocument();
    setIsOpen(false);
    goAccount();
  };

  const goToMyDocuments = (e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation();
    setIsOpen(false);
    goMyDocuments();
  };

  const goToGa4 = (e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation();
    setIsOpen(false);
    history('/google-analytics/');
  };

  const onKeyDown = (e: React.KeyboardEvent<HTMLDivElement>) => {
    if (e.key === 'Enter' || e.key === ' ') {
      e.stopPropagation();
      setIsOpen(!isOpen);
    }
  };
  const onlyShopify = user?.user.email === 'demo_spf@tigim.co';
  return (
    <Menu>
      <Div onClick={toggle} onKeyDown={onKeyDown} role='button' tabIndex={0}>
        {isLoggedIn && showAvailable && user && (
          <button className="upgrade" onClick={onOpenUpgrade}>
            <img src={creditIcon} alt="upgrade" />
            <div>
            {isLoading? (
              <><div className="loader" />Loading...</>
            ) : (
              <>{getIntegerFormatted(user?.user?.credits ?? 0)} remaining</>
            )}
            </div>
          </button>
        )}
        {!isLoggedIn && (
          <>
          <button className="login" onClick={signIn}>
            <span>Login</span>
          </button>
          {!name && (
          <button className="upgrade" onClick={contactUs}>
            <span>Sign up</span>
          </button>
          )}
          {name && !isPrepaid && !hasOnlyLicence && (
            <button className="upgrade" onClick={contactUs}>
              <img src={creditIcon} alt="upgrade" />
              <span>Get 10 credits!</span>
            </button>
          )}
          </>
        )}
        {isLoggedIn && (
          <>
            <div className="name">
              <img src={userIcon} alt="user" className="user" data-tooltip-content={name} data-tooltip-id="tooltip-general" />
              <span>Hi! {user?.user?.first_name}</span>
              <img src={downIcon} alt="menu" className="down" />
            </div>
            <img src={hamburger} alt="menu" />
          </>
        )}
      </Div>
      {isOpen && (
        <MenuDiv tabIndex={-1} role="menu" aria-hidden="false">
          <MobMenuItem
            name="Account Settings"
            onClick={goToMyAccount}
            icon={accountIcon} />
          {!onlyShopify && hasMyDocuments && (
            <MobMenuItem
              name="My Documents"
              onClick={goToMyDocuments}
              icon={documentsIcon} />
          )}
          {!onlyShopify && 
          <MobMenuItem
            name="Google Analytics"
            onClick={goToGa4}
            icon={ga4Icon} />
          }
          <MobMenuItem
            name="Logout"
            onClick={singOut}
            icon={logoutIcon} />
        </MenuDiv>
      )}
    </Menu>
  );
};

export default MenuDesktop;
